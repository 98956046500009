import React from 'react';

const Reason = props => {
  return (
    <div className="reason d-flex flex-column align-items-center text-center mx-1">
        <img alt="reason" className="reason-img mb-1" src={props.imgSrc}/>
        <h3><small>{props.title}</small></h3>
        <p className="mb-0"><small>{props.hashtag}</small></p>
        <p><small>{props.description}</small></p>
    </div>    
  );
}

export default Reason;