import React from 'react';
import TitleHeading from '../../components/TitleHeading';
import Reason from '../../components/Reason';
import FreshAndClean from '../../images/freshandclean.png';
import SavePlanet from '../../images/saveplanet.png';
import NoTouch from '../../images/notouch.png';

function Why() {
  return (
    <div id="section-why" className="section-why w-100 d-flex align-items-center">
      <div className="container d-flex flex-column my-4">
        <TitleHeading heading="WHY IZIFILL" className="align-self-center" />
        <div className="d-flex justify-content-between mt-3">
          <Reason imgSrc={FreshAndClean} title="Fresh and Clean"
            description="Grab the natural freshness and the purity of the water direct to your tumbler" />
          <Reason imgSrc={NoTouch} title="No Touch Refill"
            hashtag="#notouchrefill"
            description="Access our water station through your phone and use your own tumbler, so you can keep your hand clean" />
          <Reason imgSrc={SavePlanet} title="Save the Planet"
            description="Get fresh water while still living side by side with our beloved planet" />
        </div>
      </div>
    </div>
  );
}

export default Why; 